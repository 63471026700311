import * as React from 'react'
// material
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import Layout from '../components/layout'
import { Box, Button, AppBar, Toolbar, Container } from '@mui/material';
// components
import HomePage from '../components/heroes/HomePage';
import  MainLayout from '../layouts/main';
import { MainNavbar } from '../layouts/main/MainNavbar';
import { AboutHero, AboutWhat, AboutTeam, AboutVision, AboutTestimonials } from '../components/_external-pages/about';

import useOffSetTop from '../hooks/useOffSetTop';
// components
import Logo from '../components/Logo';
import Label from '../components/Label';
import { MHidden } from '../components/@material-extend';
//
import MenuDesktop from '../layouts/main/MenuDesktop';
import MenuMobile from '../layouts/main/MenuMobile';
import navConfig from '../layouts/main/MenuConfig';

// ----------------------------------------------------------------------

export default function AboutUsPage() {
  return (
      <HomePage title="About us">
        <AboutHero />
        <AboutWhat />
        <AboutVision />
        <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 2, height: 40 }} />
        <AboutTeam />
        <AboutTestimonials />
      </HomePage>
  );
}